import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Meta from "../../components/content/Meta";
import Container from "../../components/layout/Container";
import ArticleCategoryHome from '../../components/content/ArticleCategoryHome';
import { SettingsContext } from "../../context/SettingsContext";

export const query = graphql`
  query CategoryArticleQuery($contentful_id: String!) {
    articles: allContentfulArticle(
      filter: { category: { contentful_id: { eq: $contentful_id } } }
    ) {
      edges {
        node {
          title
          slug
          category {
            title
            slug
          }
          image {
            fluid(quality: 80, maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          date(formatString: "D MMMM YYYY")
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`;

const ArticleCategory = ({ data, pathContext }) => {
  const { setSettings } = useContext(SettingsContext);

  if (!data || !data.articles) return null;

  let { articles = [] } = data;
  articles = articles.edges.map(article => ({ ...article.node }));

  useEffect(() => {
    if (pathContext.settings) {
      setSettings(pathContext.settings);
    }
  }, []);

  return (
    <Layout pathContext={pathContext}>
      <Meta
        defaults={pathContext.settings.defaultMetadata}
        meta={{}}
      />
      <Container>
        <ArticleCategoryHome title={pathContext.categoryTitle} articles={articles} />
      </Container>
    </Layout>
  );
};

export default ArticleCategory;
