import React, { useContext, useEffect } from "react";
import Container from "../../layout/Container";
import ArticleList from "../ArticleList";
import ArticleCategories from '../ArticleCategories';
import { HeaderContext } from '../../../context/HeaderContext';
import S from "./styles";
import { ContentfulArticle } from '../../../types';
import AnimatedHeading from "../../common/AnimatedHeading";

export interface ArticleCategoryHomeProps {
  articles: ContentfulArticle[];
  title?: string;
  [prop: string]: any;
}

const ArticleCategoryHome = ({ articles, title, ...rest }: ArticleCategoryHomeProps) => {
  const { setHeaderColor, setInitialHeaderColor } = useContext(HeaderContext);

  useEffect(() => {
    setHeaderColor("light");
    setInitialHeaderColor("light");
  }, []);

  return (
    <S.ArticleCategoryHome {...rest}>
      <div className="heading">
        <h1><AnimatedHeading colourScheme="light">{`${title} Articles`}</AnimatedHeading></h1>
      </div>
      <div className="inner">
        <ArticleCategories className="category-list" />
        <ArticleList className="article-list" articles={articles} />
      </div>
    </S.ArticleCategoryHome>
  );
};

export default ArticleCategoryHome;
